import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`linting mix TS/JS codebase with ESLint 5
`}<a parentName="li" {...{
          "href": "https://linter-overload.now.sh/assets/linting-mixed-codebase.md"
        }}>{`https://linter-overload.now.sh/assets/linting-mixed-codebase.md`}</a></li>
      <li parentName="ul"><strong parentName="li">{`testing linter configs`}</strong>{` — should we? how?`}</li>
      <li parentName="ul"><strong parentName="li">{`story of jsx-no-lambda`}</strong>{` — linter induced premature optimization`}</li>
      <li parentName="ul">{`TypeScript ESLint
`}<a parentName="li" {...{
          "href": "https://github.com/typescript-eslint/typescript-eslint"
        }}>{`https://github.com/typescript-eslint/typescript-eslint`}</a></li>
      <li parentName="ul">{`Why Prettier?
`}<a parentName="li" {...{
          "href": "https://prettier.io/docs/en/why-prettier.html"
        }}>{`https://prettier.io/docs/en/why-prettier.html`}</a></li>
      <li parentName="ul">{`Dan Abramov on linting, imaginary and real problems
`}<a parentName="li" {...{
          "href": "https://overreacted.io/writing-resilient-components/"
        }}>{`https://overreacted.io/writing-resilient-components/`}</a></li>
    </ul>
    <p><strong parentName="p">{`Slides`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://linter-overload.now.sh"
      }}>{`https://linter-overload.now.sh`}</a></p>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      